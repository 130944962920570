import React from 'react';
import { m, LazyMotion, domAnimation } from 'framer-motion';
import { Flex } from 'workspace-core-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const sharedImageStyling = {
  position: 'relative',
  height: '100%',
  width: 'clamp(200px, 50vw - 1rem, 400px)',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  top: 0,
  mb: 5,
};

const AnimatedIntroImage: React.FC = ({
  imageData,
  imageType,
}: {
  imageType: 'gatsbyImage' | 'svg';
  imageData: object;
}) => (
  <LazyMotion features={domAnimation}>
    <m.div
      initial={{
        scale: 0.5,
        alignSelf: 'center',
      }}
      transition={{
        type: 'spring',
        damping: 10,
        stiffness: 500,
        delay: 0.2,
      }}
      animate={{ scale: 1 }}
    >
      {imageType === 'svg' && (
        <Flex
          dangerouslySetInnerHTML={{
            __html: imageData.data,
          }}
          sx={{
            svg: {
              ...sharedImageStyling,
            },
          }}
        />
      )}
      {imageType === 'gatsbyImage' && (
        <Flex
          image={imageData}
          sx={{
            ...sharedImageStyling,
          }}
          as={GatsbyImage}
        />
      )}
    </m.div>
  </LazyMotion>
);

export default AnimatedIntroImage;
