import React, { useEffect, useLayoutEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { Flex, Label, Display, Para, Button } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import Layout from '@containers/Layout';
import {
  forceSetSessionId,
  logItem,
  startNewSession,
} from '@slices/loggingSlice';
import useTranslation from '@hooks/useTranslation';
import BodyWrapper from '@components/BodyWrapper';
import {
  gameHasStarted,
  setHeaderType,
  resetGame,
  setCurrentLanguage,
} from '@slices/gameStateSlice';
import { initializeRoutes } from '@slices/routeSlice';
// import BackgroundCircle from '@components/BackgroundCircle';
import useSound from '@hooks/useSound';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import Seo from '@containers/Seo';
import AnimatedIntroImageWrapper from '@components/AnimatedIntroImageWrapper';
import getSymbol from '@utils/getSymbol';
import useUserLocalLanguage from '@hooks/useUserLocalLanguage';
import {
  getLocalStoreReplayCount,
  getLocalStoreSessionId,
  setLocalStoreReplayCount,
} from '@utils/localStore';

// each time you come here, we increment the replay count in localstorage so that we don't get repeated log step counts
setLocalStoreReplayCount(getLocalStoreReplayCount() + 1);

// this is to grab an approx. of user entry to site
if (typeof window !== 'undefined') {
  window.entryTime = Date.now();
}

const StartButton = styled(Button).attrs({
  forwardedAs: Link,
})(
  css({
    alignSelf: 'center',
    textDecoration: 'none',
  }),
);

const CenterContentWrapper = styled(Flex)(
  css({
    isolation: 'isolate',
    mt: 2,
    flex: 1,
    flexDirection: 'column',
  }),
);

const BottomContentWrapper = styled(Flex)(
  css({
    mt: 4,
    isolation: 'isolate',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'center',
  }),
);

const IndexPage = ({ location, data }) => {
  // this hook must always first as the first thing on our index page, however we also need to the log event for language change, and we must wait until we have a session to do that. Its a little redundant, but we have to trigger the setlang event again once we have a valid session (seen below)
  useUserLocalLanguage();

  const { allRoutes, allOriginalRoutes } = useAppSelector(state => state.route);
  /** we need to this to actually play the game */
  const { sessionId } = useAppSelector(state => state.logging);
  const { headerType, currentLanguage, defaultLanguage } = useAppSelector(
    state => state.gameState,
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { playSound } = useSound();
  const mainTitleSymbol = getSymbol(data.mainPageSymbols);

  useLayoutEffect(() => {
    // this is so we can send the lang change event once we gain a session
    if (sessionId) {
      // do session contingent stuff here, like setting a users language if it is mismatched from default due to hook
      if (currentLanguage !== defaultLanguage) {
        dispatch(
          setCurrentLanguage({
            setTo: currentLanguage,
            eventType: 'auto_toggle_lang',
          }),
        );
      }
    }
    // we really do not want this to double fire whenever language changes, we only care when session id changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage, dispatch, sessionId]);

  useEffect(() => {
    // first thing is to provision a session for the user
    // this should happen each time
    if (!sessionId) {
      // you may have an existing one set in case you refreshed the game
      const existingSessionId = getLocalStoreSessionId();
      if (existingSessionId) {
        console.info('set found session');
        dispatch(forceSetSessionId(existingSessionId));
      } else {
        console.info('start new session');
        dispatch(startNewSession({ language: currentLanguage }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useLayoutEffect(() => {
    if (headerType !== 'intro') {
      // set the right header, it changes throughout the app
      // this is in case you returned from elsewhere
      dispatch(setHeaderType({ headerType: 'intro' }));
    }
  }, [dispatch, headerType]);

  useEffect(() => {
    // log session relevant home page events here (gatsby location history events)
    // we obviously still need a sessionId here to send the log
    if (sessionId) {
      if (location?.state?.userReset) {
        dispatch(
          logItem({
            collection_name: 'events',
            event_type: 'session_kicked',
            location: window?.location?.pathname,
            target: 'user_was_reset_back_to_start',
          }),
        );
      }
      if (location?.state?.cameFromEnd) {
        dispatch(
          logItem({
            collection_name: 'events',
            event_type: 'reset_game',
            location: window?.location?.pathname,
            target: 'user_played_game_again',
          }),
        );
      }
    }
  }, [dispatch, location, location?.state?.userReset, sessionId]);

  useEffect(() => {
    // a safety precaution for if you pressed back midgame and ended up here, or reset after finishing
    dispatch(resetGame());
    // init our routes here, we use original in case you came to this page having already started game
    dispatch(initializeRoutes({ routes: allOriginalRoutes }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout shouldAnimate={false} backgroundColor="primary" location={location}>
      <Seo />
      {/* TODO: removing this as its not even used */}
      {/* <BackgroundCircle /> */}
      <BodyWrapper overflow="hidden">
        <Flex flex={1} />
        <CenterContentWrapper>
          {mainTitleSymbol ? (
            <AnimatedIntroImageWrapper
              imageData={mainTitleSymbol.data}
              imageType={mainTitleSymbol.type}
            />
          ) : (
            <Flex flex={0.5} />
          )}
          <Display mt={2} variant="gameTitle">
            {t('Main Title')}
          </Display>
          <Para as="span" mt={2} px={5} variant="p2" isBold={false}>
            <CustomMdxRenderer>{t('Main Tagline', true)}</CustomMdxRenderer>
          </Para>
        </CenterContentWrapper>
        <BottomContentWrapper>
          <StartButton
            isDisabled={sessionId === null || !sessionId}
            data-cy="start"
            to={allRoutes[0]?.url}
            state={{ cameFromEnd: false }}
            onPress={() => {
              dispatch(
                logItem({
                  collection_name: 'events',
                  event_type: 'click',
                  target: 'start button',
                }),
              );
              playSound('Button');
              dispatch(gameHasStarted());
            }}
          >
            <Label variant="l1">{t('Start Button')}</Label>
          </StartButton>
          {/* TODO: need to fuzzily decide what is considered a complete entry on airtable, as in, what constitutes a valid Content entry */}
          {t('Start Button Aside').length > 5 && (
            <Para mt={5} variant="p3" as="aside">
              {t('Start Button Aside')}
            </Para>
          )}
        </BottomContentWrapper>
      </BodyWrapper>
    </Layout>
  );
};

// define some common gql fragment accessor heres as well
export const query = graphql`
  fragment SvgGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childSvg {
                  content {
                    data
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment AbsoluteGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                publicURL
                name
              }
            }
          }
        }
      }
    }
  }

  fragment GatsbyImageGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }

  fragment GatsbyImageGetFragmentNoPlaceholder on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  }

  query mainPageImage {
    mainPageSymbols: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Main Title" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;
export default IndexPage;
